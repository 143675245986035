import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import useAudio from '../../hooks/use-audio'
import { Link } from 'gatsby'
const AtardecerPage = () => {
  useAudio("https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/pajaros-centro-valladolid.mp3")

  return (
    <Layout>
      <SEO title="Atardecer" />
      <nav class="navbar">
        <Link class="navbar-brand" to="/">Volver</Link>
      </nav>
      <div className="row justify-content-end">
        <div className="col-12 col-md-8">
          <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-26.jpg" alt="Arbol" className="img-fluid" />
        </div>
      </div>
      <h1 className="text-center mt-5 mb-5">Acto Atardecer</h1>
      <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-29.jpg" alt="Atardecer" className="img-fluid" />
    </Layout>
  )
}

export default AtardecerPage
